import { ResponseType, StringTests } from '../../../constants';

import RecaptchaForm from '../../../components/mixins/RecaptchaForm';

import HasPlans from '../../service-plan/mixins/HasPlans';

const PlanSelector = () => import('@/modules/service-plan/components/PlanSelector.vue');

export default {
  name: 'RegisterForm',

  mixins: [HasPlans, RecaptchaForm],

  props: {
    targetSubscription: {
      type: String,
      default: undefined,
    },
    store: {
      type: String,
      default: 'login-extended'
    },
  },

  components: {
    PlanSelector,
    TosDialog: () => import('@/modules/user-profile/components/TosDialog.vue'),
  },

  data() {
    return {
      showTosDialog: false,
      formSending: false,
      formSentSuccessfully: false,
      pending: false,
      errorMessage: '',
      valid: false,
      password: null,
      passwordRules: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.password_required'),
        (v) => (typeof v === 'string' && v.length >= 6) || this.$vuetify.lang.t('$vuetify.alerts.password_minlength'),
      ],
      confirmPassword: null,
      confirmPasswordRules: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.confirmPassword_required'),
        (v) => (typeof v === 'string' && v.length >= 6) || this.$vuetify.lang.t('$vuetify.alerts.password_minlength'),
        (v) =>
          this.password === this.confirmPassword || this.$vuetify.lang.t('$vuetify.alerts.confirmPassword_notMatch'),
      ],
      email: null,
      emailAdditionalRule: false,
      emailValidating: false,
      useAdditionalEmailRule: false,
      firstName: null,
      firstNameRules: [(v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.firstName_required')],
      lastName: null,
      lastNameRules: [(v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.lastName_required')],
      consent: false,
      consents: [
        {
          name: 'TosAcceptedOn',
          value: false,
          rules: [(v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.consent_required')],
          label: this.$vuetify.lang.t('$vuetify.registerForm.consent'),
          trueValue: new Date().toISOString(),
          falseValue: '',
          dialog: {
            text: 'FlightScope Terms and Conditions',
            click: (e) => { e.preventDefault(); this.showTosDialog = true},
          },
        },
      ],
    };
  },

  computed: {
    emailRules() {
      const rules = [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.email_required'),
        (v) => StringTests.email4.test(v) || this.$vuetify.lang.t('$vuetify.alerts.email_not_valid'),
      ];
      if (this.emailAdditionalRule) {
        rules.push(this.emailAdditionalRule);
      }
      return rules;
    },
    selectedPlan: {
      get() {
        return this.planListItems.find((plan) => plan.level === this.targetSubscription);
      },
      set(plan) {
        this.$router.push({ name: 'account.register.plan', params: { plan: plan.level } });
      },
    },
  },

  methods: {
    validateForm() {
      return this.$refs.registerForm.validate();
    },

    resetForm() {
      return this.$refs.registerForm.reset();
    },

    async submit() {
      const formValid = this.validateForm() && this.useAdditionalEmailRule;

      if (formValid) {
        this.formSending = true;

        const registerUserResult = await this.registerUser();

        this.formSending = false;
        if (registerUserResult.type === ResponseType.SUCCESS) {
          this.resetForm();
          this.formSentSuccessfully = true;
        } else {
          this.errorMessage = registerUserResult.message;
        }
      }
    },

    async verifyEmail() {
      const isValidFormat = StringTests.email.test(this.email);
      this.useAdditionalEmailRule = false;

      if (!isValidFormat) {
        return;
      }
      this.emailValidating = true;

      let payload = {
        email: this.email,
      };

      if (this.enableRecaptcha) {
        payload.rcToken = await this.recaptchaToken('emailCheck');
      }

      const emailExistsResult = await this.$store.dispatch(`${this.store}/emailExist`, payload);

      this.useAdditionalEmailRule = !emailExistsResult;

      this.emailAdditionalRule = emailExistsResult ? this.$vuetify.lang.t('$vuetify.alerts.email_in_use') : false;

      this.emailValidating = false;
    },

    async registerUser() {
      let payload = {
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        displayName: `${this.firstName} ${this.lastName}`,
        password: this.password,
        // Pass only needed consent properties
        consents: this.consents.map((consent) => ({
          name: consent.name,
          value: consent.value,
        })),
        // TODO: 1 - male. For now this value is hardcoded
        genderID: 1,
        targetSubscription: this.targetSubscription,
        strict: 1,
      };

      if (this.enableRecaptcha) {
        payload.rcToken = await this.recaptchaToken('register');
      }

      const registerUserResult = await this.$store.dispatch(`${this.store}/registerUser`, payload);

      return registerUserResult;
    },
  },
};
